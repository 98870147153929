body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
main {
    flex: 1 0 auto;
}

button {
    margin: 20px;
}

.brand-logo {
    margin-left: 20px;
    margin-top: 10px;
    background: url("/logo.svg")
    no-repeat;
    width: 276px;
    height: 40px;
    display: block;
    text-indent: -9999px;
}

#nav-mobile > li > a {
    color: black;
}